export const showEnv = (): void => {
  console.debug('process.env', process.env);
};

export const getApiBaseUrl = (): string => {
  switch (process.env?.REACT_APP_API || process.env?.REACT_APP_ENV) {
    case 'local': {
      return 'http://localhost:8000/api/';
    }
    case 'development': {
      return 'https://dev.tiitus.fi/api/';
    }
    default:
      return 'https://secure.tiitus.fi/api/';
  }
};

export const getMarketUrl = (): string => {
  switch (process.env?.REACT_APP_MARKET || process.env?.REACT_APP_ENV) {
    case 'local': {
      return `http://localhost:8080/search/jobs`;
    }
    case 'development': {
      return `https://appdev.tiitus.fi/search/jobs`;
    }
    default:
      return `https://app.tiitus.fi/search/jobs`;
  }
};

export const getCompanyRegisterUrl = (sectorId: number): string => {
  switch (
    process.env?.REACT_APP_COMPANY_REGISTER ||
    process.env?.REACT_APP_ENV
  ) {
    case 'local': {
      return `http://localhost:8081/register/?type=employer&sector=${sectorId}`;
    }
    case 'development': {
      return `https://uusidev.tiitus.fi/register/?type=employer&sector=${sectorId}`;
    }
    default:
      return `https://uusi.tiitus.fi/register/?type=employer&sector=${sectorId}`;
  }
};

export const getTiitusIntroUrl = (sectorNameSlug: string): string => {
  switch (process.env?.REACT_APP_TIITUS_INTRO || process.env?.REACT_APP_ENV) {
    case 'local': {
      return `http://localhost:8081/${sectorNameSlug}/intro`;
    }
    case 'development': {
      return `https://uusidev.tiitus.fi/${sectorNameSlug}/intro`;
    }
    default:
      return `https://uusi.tiitus.fi/${sectorNameSlug}/intro`;
  }
};

export const getStudentRegisterUrl = (sectorNameSlug: string): string => {
  switch (
    process.env?.REACT_APP_STUDENT_REGISTER ||
    process.env?.REACT_APP_ENV
  ) {
    case 'local': {
      return `http://localhost:8080/${sectorNameSlug}/register/`;
    }
    case 'development': {
      return `https://appdev.tiitus.fi/${sectorNameSlug}/register/`;
    }
    default:
      return `https://app.tiitus.fi/${sectorNameSlug}/register/`;
  }
};

export const CONTINUOUS_SEARCH_DATE = '2050-01-01T00:00:00Z';

export const getLimitedTextString = (
  textString: string,
  limit: number,
): string => {
  if (textString.length > limit) {
    return textString.substring(0, limit - 3) + '...';
  } else {
    return textString;
  }
};
